
 .div_containe{
    min-height: 100vh;
    background-image: url(../images/fondorecover.jpg);
    background-repeat: no-repeat;
    background-position: 0px 58px center center;
    background-attachment: fixed;
    background-size: cover;
  
  }
  