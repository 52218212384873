img {
    float: left;
    margin-left: 15px;     
}

#titleNoty {
    font-size: medium;
    font-weight: bold;
    color:#1c1b1b;
  }

#messageNoty {
    font-size: small;
    color:#646263;
  }

  .content-wrapper{
    font-size: 85%;
  }

  #tabla_noti{
  overflow:scroll;
  height:380px;  
 
}

  