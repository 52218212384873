.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a{
    background-color: #AD1457 ;
    border-color:  #AD1457 ;
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid #AD1457 ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color:  #AD1457 ;
    border-color: #AD1457;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #AD1457
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }