body{
  background-image: url(../images/background_login_panel.png); 
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  height: 100vh;

  overflow-x: hidden;
}

/*#card_body{
  background-image: url(../images/background_card.png); 
  border-radius: 15%;
}*/

#card_login{
  max-width: 400px;  
  border: 0px;
  border-radius: 15px;
  


  margin-bottom: 10px; /* Added */
  box-shadow: 0px 10px 10px 10px #999;

  display: block;
  margin-left: auto;
  margin-right: auto;  
  
  
  }



#titulo{
  margin-top: 7em;  
  margin-bottom: 1em;
  margin-right: 21em;
  color:#6f6f6f;    
  
  display: block;
  margin-left: auto;
  margin-right: auto;
  
}


.card-title {
  color: #AD1457;
}

#image {
  margin-top: 15em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


#btn-ttc,
#btn-ttc:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 70%;
  border-radius: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#icono{
  color:#ffffff;  
  background-color: #AD1457;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#show_password{
  color:#AD1457;  
  background-color: #ffffff;

  border-top: 1px solid #c2c2c2 !important;
  border-bottom: 1px solid #c2c2c2 !important;
  border-left: 0px solid #c2c2c2 !important;
  border-right: 1px solid #c2c2c2 !important;
}

#btn-ttc:hover,
#show_password:hover{  
  color:#ffffff;  
  background-color: #7e0a3c; 
}

#btn-recoveripass,
#btn-recoveripass:defined {
  color:#9d9999; 
  background-color:  #ffffff;
  border-radius: 15px;
  border: 0px;
  font-size: small;
}

#btn-recoveripass:hover{  
  color:#020202;  
  background-color:#ffffff;    
  border-radius: 15px;
  border: 0px;
 
}

#btn-activarcuenta,
#btn-activarcuenta:defined {
  color:#AD1457; 
  background-color:  #ffffff;
  border-radius: 15px;
  border: 0px;
  font-size: small;
}

#btn-activarcuenta:hover{  
  color:#7e0a3c;  
  background-color:#ffffff;    
  border-radius: 15px;
  border: 0px;
 
}

#btn-registrarse,
#btn-registrarse:defined {
  color:#AD1457; 
  background-color:  #ffffff;
  border-radius: 15px;
  border: 0px;
  font-size: small;
  text-decoration-line: underline;
}

#btn-registrarse:hover{  
  color:#7e0a3c;  
  background-color:#ffffff;    
  border-radius: 15px;
  border: 0px;
  text-decoration-line: underline;
 
}

#btn-cambiocontra,
#btn-cambiocontra:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 50%;
  border-radius: 15px;
}

#btn-cambiocontra:hover{  
  color:#ffffff;  
  background-color: #7e0a3c;    
 
}

#btn-activacuenta,
#btn-activacuenta:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 50%;
  border-radius: 15px;
}

#btn-activacuenta:hover{  
  color:#ffffff;  
  background-color: #7e0a3c;    
 
}



header a {
  color:#646263;
}

.card  a {
  color:#AD1457;
}

.card  a:hover {
  color:#000;
}

header p {
  font-size: small;
  color:#646263;
}

.form-control  {  
  /* border-color:#888484; */
  border-radius: 5px;
  /*background-color: #eee; */
  /*border: 0; */
  outline-color:#8fbff5;
  /*outline:none; */
  border: 1px solid rgb(190, 190, 190);
  
}

#txtPassword,
#input,
#input1{
  font-size: 15px;
  background-color:transparent;
}




  