#estado{
    font-size: 35px;
}

#lavel{
    color: #6f6f6f
}

#btn{

    background-color: #AD1457;
    border-color: #AD1457;
}

#btn_finalizarcompra{

    background-color: #AD1457;
    border-color: #AD1457;
}

#btn_finalizarcompra_false{

    background-color: #AD1457;
    border-color: #AD1457;
}