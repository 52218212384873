.btn-edit,
.btn-edit:defined,
.btn-edit:active {
  color:#AD1457;
  text-shadow: 0 -px 0 #ffffffe8; 
     
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;        

 
}


.btn-edit:hover
{

  background-color: #ebeaea;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;        

 
}

