.btn-see,
.btn-see:defined,
.btn-see:active {
  color:#AD1457;
  text-shadow: 0 -px 0 #ffffffe8; 
     
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;        

 
}
