#cont{
    max-width: 900px;
    height: 100hv;
}

#cont #title{
    color:#AD1457;
    text-align: center;
}

/* Inactive tab */
#cont .myClass {
    background-color: white;
    color: red;
}
.myClass ul > li > a:hover {
    background-color: pink;
}

/* Active tab */
.myClass ul > li.active > a,
.myClass ul > li.active > a:hover,
.myClass ul > li.active > a:focus {
    background-color: red;
    color: white;
}

/* Disabled tab */
.myClass ul > li.disabled > a {
    background-color: lightgray;
    color: darkgray;
}

#modal-editconvenio hr{
    border-color:#AD1457;
}

/* style 7 */

.inputfile-7 + label {
    color: #a5a5a5;
  }
  
  .inputfile-7:focus + label,
  .inputfile-7.has-focus + label,
  .inputfile-7 + label:hover {
    color: #575655;
  }
  
  .inputfile-7 + label figure {
    width: 30px;
 
  }
  
  .inputfile-7:focus + label figure,
  .inputfile-7.has-focus + label figure,
  .inputfile-7 + label:hover figure {
    background-color: #bfbfbf;
  }
  
  .inputfile-7 + label svg {
    width: 100%;
    height: 100%;
    fill: #fff;
  }

 