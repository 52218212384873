.div_container{
  max-width: 450px !important;
  margin-top: 0px;
  border-color:#AD1457 ;   
  height: 200px;
  margin: 0 auto;
  
  
  }

.div_container {
border-color: #AD1457;
}

#titulo_movil{
  color:#6f6f6f;    
}


.card-title {
  color: #AD1457;
}

#image_movil {

  display: block;
  margin-left: auto;
  margin-right: auto;
}


#btn-ttc_movil,
#btn-ttc_movil:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 100%;
  border-radius: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#show_password_movil{
  color:#ffffff;  
  background-color: #AD1457;
}

#btn-ttc:hover,
#show_password_movil:hover{  
  color:#ffffff;  
  background-color: #7e0a3c;    
 
}

#btn-recoveripass,
#btn-recoveripass:defined {
  color:#9d9999; 
  background-color:  #ffffff;
  
  border-radius: 15px;
  border: 0px;
}

#btn-recoveripass:hover{  
  color:#020202;  
  background-color:#ffffff;    
  border-radius: 15px;
  border: 0px;
 
}

#btn-cambiocontra,
#btn-cambiocontra:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 50%;
  border-radius: 15px;
}

#btn-cambiocontra:hover{  
  color:#ffffff;  
  background-color: #7e0a3c;    
 
}


header a {
  color:#646263;
}

.card  a {
  color:#AD1457;
}

.card  a:hover {
  color:#000;
}

header p {
  font-size: small;
  color:#646263;
}

.form-control  {  
  /* border-color:#888484; */
  border-radius: 5px;
  /*background-color: #eee; */
  /*border: 0; */
  outline-color:#8fbff5;
  /*outline:none; */
  border: 1px solid rgb(190, 190, 190);
  
}

.card input{
  font-family: Arial,Helvetica,sans-serif;
       font-size: 18px;
}

#txtPassword,
#input{
  font-size: 15px;
  background-color:transparent;
}


  