
#tabla_intervalos {
  overflow:scroll;
  height:50px;
 
}
#tabla_intervalos table {
 width:100px;
 background-color:lightgray;
}
