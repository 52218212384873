#btn_load {
    color:#ffffff;
    background-color: #AD1457;
    float: center;
   
  }
#btn_load:hover{  
     
      background-color: #7e0a3c;    
     
    }


    input[type="file"] {
        display: none;
    }
    .custom-file-upload {
        color:#ffffff;
        background-color: #AD1457;
        
        
        padding: 6px 12px;
        cursor: pointer;
    }


  .card hr{
      border-color:#AD1457;
  }

  .card p {
    font-size: medium;
    color:#646263;
  }
