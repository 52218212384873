#nav2{
    background-color: #AD1457;
}


#icono_crea{
    color:#c4c4c4;  
    background-color: #ffffff;
    border:solid 1px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
}

#btn_sigte{
    color:#ffffff;
    
    background-color: #AD1457;
    float: right;
   
}

  #btn_anterior{
    color:#ffffff;
    
    background-color: #AD1457;
    float: left;
   
}

#btn-registrar,
#btn-registrar:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 50%;
  border-radius: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}



#btn-cargar,
#btn-cargar:defined {
  color:#ffffff; 
  background-color: #AD1457;
  
  border-radius: 5px;

  font-size: 10px;
}

#btn_recupera{

  color:#ffffff; 
  background-color: #AD1457;
  border-color: #AD1457;

}

#btn_recupera:hover{

  color:#ffffff; 
  background-color: #7e0a3c;

}

#hr_vertical1{
    border-left: 1px solid #afafaf;      
}

#hr_horizontal1{
    border-top: 1px solid #afafaf;      
}

#medida{
  font-size: 12px;
  color: #666664;
}

#portada_blanco{   
    display: block;
    margin: auto;
    border-radius: 100em;
 }

 #avatar_blanco{   
  height:auto;
  margin-left: auto;
 
}

 #foto_portada {
  position: absolute; right: -300px; bottom: 0px;
  }


#card_body{
  border-radius: 15px;
}

#correo_activacion{
  color: #AD1457;
}

#cod_activa{
  width: 50px;
  margin-left: 4px;
  margin-right: 4px;
}

#exampleCheck1{
  width:30px;
  height:30px;  
  
}

#check_terminos{
  margin-top: 8px;
  margin-left: 20px;
  text-decoration-line: underline;

}

#check_terminos:hover{
  margin-top: 8px;
  margin-left: 20px;
  text-decoration-line: underline;
  color:#AD1457;

}

#asterisco {
 
  color:#f03f3f;
  border-top: 0px;
}

#campos_obligatorios {
  font-size: small;
  border-top: 0px;
  background-color:  #ffffff;
  border: 0px;
}

#campos_obligatorios:hover {
  font-size: small;
  border-top: 0px;
  color: #666664;
  background-color:  #ffffff;
  border: 0px;
}

#p_comprar{
  margin-top: 0;
  margin-bottom: 0;
  color: #3d3d3d;
  font-weight: bold;
  font-size: 16px;
}

#info_compra{
  margin-top: 0;
  margin-bottom: 0;
  color:#9e9e9e;
  font-size: 15px;
}

#h4_compra{
  color: #3d3d3d;
  font-weight: bold;
}

#titulo_terminos{
  text-align: justify;
  text-align: center;
}

#texto_terminos{
  text-align: justify;
  
}




@media only screen and (max-width: 1920px) {

  #img_center {
   
    display: block;
    margin: auto;
    margin-right: 370px;
 
}

  
  #btn-tengocuenta,
  #btn-tengocuenta:defined {
    color:#ffffff; 
    background-color:  #AD1457;
    border-radius: 15px;
    border: 0px;
    margin-right: 120px;
  }
  
  #btn-tengocuenta:hover{  
    color:#d5d5d5;  
    background-color:#AD1457;    
    border-radius: 15px;
    border-color: 1px;  
   
  }
  
}

@media only screen and (max-width: 767px) {

  #img_center {
   
    display: block;
    margin: auto;
    margin-right: 20px;
 
}

  
  #btn-tengocuenta,
  #btn-tengocuenta:defined {
    color:#ffffff; 
    background-color:  #AD1457;
    border-radius: 15px;
    border: 0px;
    margin-right: 20px;
  }
  
  #btn-tengocuenta:hover{  
    color:#d5d5d5;  
    background-color:#AD1457;    
    border-radius: 15px;
    border-color: 1px;  
   
  }

  

}




/* style 6 */

.inputfile-6 + label {
    color: #666664;
  }
  
  .inputfile-6:focus + label,
  .inputfile-6.has-focus + label,
  .inputfile-6 + label:hover {
    color: #100a04;
  }
  
  
  
  .inputfile-6:focus + label figure,
  .inputfile-6.has-focus + label figure,
  .inputfile-6 + label:hover figure {
    background-color: #bfbfbf;
  }
  
  .inputfile-6 + label svg {
    width: 100%;
    height: 100%;
    fill: #fff;
  }

  input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

  