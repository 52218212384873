
#btn-options {
    color:#ffffff;
    background-color: #AD1457;
    float: center;
    width: 100%;
   
  }

#btn-options:hover{  
     
      background-color: #7e0a3c;    
     
  }

#btn-platinium {
      color:#ffffff;
      background-color: #3c474f;
      float: center;
      width: 100%;
     
  }

#btn-golden {
    color:#ffffff;
    background-color: #3f2311;
    float: center;
    width: 100%;
   
}

#btn-diamond {
  color:#ffffff;
  background-color: #004740;
  float: center;
  width: 100%;
 
}

#btn-platinium:hover{  
       
        background-color: #2b3338;    
       
  }

#btn-golden:hover{  
       
    background-color: #311b0d;    
   
}

#btn-diamond:hover{  
       
  background-color: #00312d;    
 
}


