@media only screen and (max-width: 1920px) {
  #portada{   
      height:400px;  
      margin-left: auto;
    }

  #avatar{   
      height:180px;  
      margin-left: auto;
    }

    #perfil_avatar{
      position: absolute; right: 50px; bottom: 375px;
      border-radius: 150px;
      /*border: 5px solid #ffffff !important;*/
     
    }
  
  }

  @media only screen and (max-width: 767px) {
    #portada{   
        height:auto;
        margin-left: auto;
      }

      #avatar{   
        height:70px;  
        margin-left: auto;
      }

      #perfil_avatar{
        position: absolute; right: 30px; bottom: 480px;
        border-radius: 150px;
        /*border: 5px solid #ffffff !important;*/
       
      }      
    
    }

/*#avatar
  {
    position:absolute;
    height:300px;
    width:300px;
    z-index: -1;
  } */

#nit_cod{
    color:#838181; 
    font-size: medium;
 } 

#linea{
    border-color:#afafaf !important;
    border: 1px solid;

 }

#hr_vertical{

    border-left: 2px solid #afafaf;
      
}

#web{  
  color:#666565; 
  background-color:  #ffffff;
  border-radius: 15px;
  border: 0px;
      
  }

#web:hover{  
  color:#AD1457;  
  background-color:#ffffff;    
  border-radius: 15px;
  border: 0px;
   
   
  }

#info{
    color:#AD1457;
    font-size: 18px;
  }

#act_plan{
    color:#6b6b6b; 
   
}

#cod_membresia{
  width: 80px;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 14px;
}

#modal-activamembresia{  
  top: 100px;  
  
}


#carouselExampleControls{
    background-color: #e4e4e4;
}

.carousel-control-prev-icon{ 

    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%767676' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");

}

.carousel-control-next-icon{ 
     
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%767676' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}


#btn-comprar {
  color:#ffffff; 
  background-color: #AD1457;
  border-radius: 15px; 
  display: block;
  margin-left: 1em;
}

#btn-comprar:hover{  
  color:#ffffff;  
  background-color: #7e0a3c;    
 
}

#espacios{
    margin-top: 0;
    margin-bottom: 0;
    color: #3d3d3d;
    font-weight: bold;
}

#info_institucion{
    margin-top: 0;
    margin-bottom: 0;  
}

#texto{
    
    color: #3d3d3d;
    font-weight: bold;
    font-size: large;
}

#titulo_plan{
    color:#AD1457;
  
}

#custom-tabs-three-home{
  position: relative;
}

#perfil_cover{
  position: relative;
}



#act_avatar{

  border: 5px solid #dfdfdf !important;
 
}

#file_portada{
  position: absolute; bottom: 20px; 
}

#file_avatar{
  position: absolute; bottom: -10px; 
}

#fondo_modal_compra{
  background-color: #FBF4F7 ;
  
}

#btn_compra_paso1{
  background-color: #FBF4F7 ;
}

#btn_compra{
  background-color: #AD1457 ;
  border-color: #AD1457 ;
}

#btn_compra:hover{
  background-color: #7e0a3c ;
  border-color: #7e0a3c ;
}

#btn-llaveswompi,
#btn-llaveswompi:defined {
  color:#3d3d3d; 
  background-color:  #ffffff;
  border-color: #dfdfdf;
  border-radius: none;
  border: none;
  font-size: medium;
}

#btn-llaveswompi:hover{  
  color:#AD1457;  
  background-color:#ffffff; 
  border-color: #dfdfdf;   
  border-radius:none;
  border:none;
 
}

#btn-verdetalle,
#btn-verdetalle:defined {
  color: #3d3d3d; 
  border-radius: none;
  border: none;
  font-size: medium;
  text-decoration-line: underline;
}

#btn-verdetalle:hover{  
  color: #7e0a3c;
  border-radius: none;
  border: none;
  text-decoration-line: underline; 
}


#img_pasoss{

  display: block;
  margin: auto;

}

.tab-contente {
  height:auto;
}

/* style 6 */

.inputfile-6 + label {
  color: #666664;
}

.inputfile-6:focus + label,
.inputfile-6.has-focus + label,
.inputfile-6 + label:hover {
  color: #100a04;
}



.inputfile-6:focus + label figure,
.inputfile-6.has-focus + label figure,
.inputfile-6 + label:hover figure {
  background-color: #bfbfbf;
}

.inputfile-6 + label svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}

#tabla_publicaciones {
  overflow:scroll;
  height:500px;
}

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.card-body::-webkit-scrollbar {
  -webkit-appearance: none;
}

.card-body::-webkit-scrollbar:vertical {
  width:10px;
}

.card-body::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
  display: none;
} 

.card-body::-webkit-scrollbar:horizontal {
  height: 10px;
  display: none;
}

.card-body::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.card-body::-webkit-scrollbar-track {
  border-radius: 10px;  
}






  