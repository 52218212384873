img {
    float: left;
    margin-left: 15px;     
}

#nav{
  background-color: #c9c9c9;
}

#footer{
  align-content:center;
}



  