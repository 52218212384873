#li_derecha{
    
  margin-left:64%;
  
}

/* style 5 */

.inputfile-5 + label {
  color: #666664;
}

.inputfile-5:focus + label,
.inputfile-5.has-focus + label,
.inputfile-5 + label:hover {
  color: #100a04;
}

.inputfile-5 + label figure {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #d4dcd9;
  display: block;
  padding: 20px;
  margin: 0 auto 10px;
}

.inputfile-5:focus + label figure,
.inputfile-5.has-focus + label figure,
.inputfile-5 + label:hover figure {
  background-color: #bfbfbf;
}

.inputfile-5 + label svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}

#dropdown_options{  
  color:#AD1457;  
  background-color: #ffffff;    
 
}

#dropdown_options:hover{  
  color:#ffffff;  
  background-color: #c03232;    
 
}

#btn_noreaccion{  
  color:#615e5e;
  background: #ffffff;
  border-radius: 10px;
  border: none;
 
}



#btn_reaccion{  
  color:#AD1457;
  border: none;
  background: #ffffff;
  border-radius: 10px;
 
}

#btn_reaccion:hover{  
  color:#AD1457;
  border: none;
  background: #e1e1e1;
  border-radius: 10px;
 
}


#btn_noreaccion_interesa{  
  color:#615e5e;
  border: 1px solid #615e5e;
  background: #ffffff;
  border-radius: 10px;
 
}

#btn_noreaccion_interesa:hover{  
  color:#615e5e;
  border: 1px solid #615e5e;
  background: #ffffff;
  border-radius: 10px;
 
}

#btn_reaccion_interesa{  
  color:#AD1457;
  border: 1px solid #AD1457;
  background: #ffffff;
  border-radius: 10px;
 
}

#btn_reaccion_interesa:hover{  
  color:#AD1457;
  border: 1px solid #AD1457;
  background: #e1e1e1;
  border-radius: 10px;
 
}

#tabla_imgs {
  overflow-y:scroll;
  height:290px;  
 
}

#tabla_reacciones {
  overflow-y:scroll;
  height:400px;  
 
}





#vdsdetalle{
  width:100%;
  max-width:765px;
}


#imgs,
#vdos{
  border:1px solid #AD1457;
}



@media only screen and (max-width: 1920px) {
  
  #modal-detallepubli{  
    left: 350px;  
    margin-left: -225px;
  }

  #imgsdetalle{
    width:100%;
    max-width:735px;
  }
  
}

@media only screen and (max-width: 767px) {

  #modal-detallepubli{ 
    left:0px; 
    margin-left: 0;
  }

  #imgsdetalle{
    width:100%;
    max-width:535px;
    margin-left: auto;
  }

  

}

#info_reaccion{
  color: #100a04;
}
 







#publicaciones{
        margin: 0 auto; /* Added */
        float: none; /* Added */
        margin-bottom: 10px; /* Added */
        box-shadow: 2px 2px 5px #999;
        
        border-top: 1px solid #d3d3d3 !important;
        border-bottom: 1px solid #d3d3d3 !important;
        border-left: 1px solid #d3d3d3 !important;
        border-right: 1px solid #d3d3d3 !important;
        border-radius: 10px;
        
}

#image_publi {
  display: block;
  float:left;
}

#textarea_descripcion{
  white-space: pre-line;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
}


#textarea_descripcion:hover{
  white-space: pre-line;
  display: block;
  
}


#textarea_descripcion_vermas{
  white-space: pre-line;
}


#btn_vermas,
#btn_vermas:defined {
  color:#AD1457; 
  background-color:  #ffffff;
  border: none;
  font-size: small;
}

#btn_vermas:hover{  
  color:#7e0a3c;  
  background-color:#ffffff;    
 
  border: none;
  border-color: #ffffff;
 
}

#btn_vermenos,
#btn_vermenos:defined {
  color:#AD1457; 
  background-color:  #ffffff;
  border: none;
  font-size: small;
}

#btn_vermenos:hover{  
  color:#7e0a3c;  
  background-color:#ffffff;    
 
  border: none;
  border-color: #ffffff;
 
}


#image_publi {

  display: block;
  margin-left: auto;
  margin-right: auto;
}


.aws-btn {

--slider-height-percentage: 60%;
--slider-transition-duration: 700ms;
--organic-arrow-thickness: 4px;
--organic-arrow-border-radius: 0px;
--organic-arrow-height: 40px;
--organic-arrow-color: #538bd5;
--control-button-width: 10%;
--control-button-height: 25%;
--control-button-background: transparent;
--control-bullet-color: #62a4fa;
--control-bullet-active-color: #538bd5;
--loader-bar-color: #851515;
--loader-bar-height: 6px;
}


