.sidebar{
    background-color:#AD1457;
    height: 100%;
    
}

.sidebar li i,
.sidebar li p{
    color: #f5f5f5;
   
}

.sidebar li {
    transition: 0.7s;
    transition-property:background-color ;    
  }

.sidebar li:hover {
    background-color: #7e0a3c;
    text-decoration: none;
    color:#fff;    
    border-radius: 5px;
}


.brand-link{
    background-color:#AD1457;   
}

#item_ips span {
    font-size: small;
    color:#fff;
  }

.activo{
    /* font-weight: bold; */
    background-color:#7e0a3c ;
}