body{
  background-color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  height: 100vh;

  overflow-x: hidden;
}

#title{
    color:#AD1457;
}

.card-primary{
    border-color: #c0bbb7 !important;
}

#btn_crear{
  color:#ffffff;
  
  background-color: #AD1457;
  float: right;
 
}
#btn_crear:hover{  
   
    background-color: #7e0a3c;    
   
  }

.modal-footer a:hover{    
    background-color:#0275d8;  
    color:#ffffff;  
   
  }

#title{
    color:#AD1457;   
}

#modal-respuesta h6{
  color:#f03f3f;
}

#modal-respuesta p {
  font-size: small;
  color:#f03f3f;
}

#tabla1,#tabla2,#tabla3,#tabla4 {
  overflow:scroll;
  height:100%;  
 
}


#tabla table {
 width:380px;
 background-color:lightgray;
}


#spamloader {
  font-size: x-large;
  color:#fafafa
}

#eps_pcte,
#regimen_pcte{
  font-size: 14px;

}

#link_pqrs{
  font-size: 13px; 
  font-style: unset;
}


/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.card::-webkit-scrollbar {
  -webkit-appearance: none;
}

.card::-webkit-scrollbar:vertical {
  width:10px;
}

.card::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
  display: none;
} 

.card::-webkit-scrollbar:horizontal {
  height: 10px;
  display: none;
}

.card::-webkit-scrollbar-thumb {
  background-color: #AD1457;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.card::-webkit-scrollbar-track {
  border-radius: 10px;  
}