@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body{
  background-image: url(../../static/media/background_login_panel.a2da55b5.png); 
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  height: 100vh;

  overflow-x: hidden;
}

/*#card_body{
  background-image: url(../images/background_card.png); 
  border-radius: 15%;
}*/

#card_login{
  max-width: 400px;  
  border: 0px;
  border-radius: 15px;
  


  margin-bottom: 10px; /* Added */
  box-shadow: 0px 10px 10px 10px #999;

  display: block;
  margin-left: auto;
  margin-right: auto;  
  
  
  }



#titulo{
  margin-top: 7em;  
  margin-bottom: 1em;
  margin-right: 21em;
  color:#6f6f6f;    
  
  display: block;
  margin-left: auto;
  margin-right: auto;
  
}


.card-title {
  color: #AD1457;
}

#image {
  margin-top: 15em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


#btn-ttc,
#btn-ttc:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 70%;
  border-radius: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#icono{
  color:#ffffff;  
  background-color: #AD1457;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#show_password{
  color:#AD1457;  
  background-color: #ffffff;

  border-top: 1px solid #c2c2c2 !important;
  border-bottom: 1px solid #c2c2c2 !important;
  border-left: 0px solid #c2c2c2 !important;
  border-right: 1px solid #c2c2c2 !important;
}

#btn-ttc:hover,
#show_password:hover{  
  color:#ffffff;  
  background-color: #7e0a3c; 
}

#btn-recoveripass,
#btn-recoveripass:defined {
  color:#9d9999; 
  background-color:  #ffffff;
  border-radius: 15px;
  border: 0px;
  font-size: small;
}

#btn-recoveripass:hover{  
  color:#020202;  
  background-color:#ffffff;    
  border-radius: 15px;
  border: 0px;
 
}

#btn-activarcuenta,
#btn-activarcuenta:defined {
  color:#AD1457; 
  background-color:  #ffffff;
  border-radius: 15px;
  border: 0px;
  font-size: small;
}

#btn-activarcuenta:hover{  
  color:#7e0a3c;  
  background-color:#ffffff;    
  border-radius: 15px;
  border: 0px;
 
}

#btn-registrarse,
#btn-registrarse:defined {
  color:#AD1457; 
  background-color:  #ffffff;
  border-radius: 15px;
  border: 0px;
  font-size: small;
  text-decoration-line: underline;
}

#btn-registrarse:hover{  
  color:#7e0a3c;  
  background-color:#ffffff;    
  border-radius: 15px;
  border: 0px;
  text-decoration-line: underline;
 
}

#btn-cambiocontra,
#btn-cambiocontra:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 50%;
  border-radius: 15px;
}

#btn-cambiocontra:hover{  
  color:#ffffff;  
  background-color: #7e0a3c;    
 
}

#btn-activacuenta,
#btn-activacuenta:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 50%;
  border-radius: 15px;
}

#btn-activacuenta:hover{  
  color:#ffffff;  
  background-color: #7e0a3c;    
 
}



header a {
  color:#646263;
}

.card  a {
  color:#AD1457;
}

.card  a:hover {
  color:#000;
}

header p {
  font-size: small;
  color:#646263;
}

.form-control  {  
  /* border-color:#888484; */
  border-radius: 5px;
  /*background-color: #eee; */
  /*border: 0; */
  outline-color:#8fbff5;
  /*outline:none; */
  border: 1px solid rgb(190, 190, 190);
  
}

#txtPassword,
#input,
#input1{
  font-size: 15px;
  background-color:transparent;
}




  
body{
  background-color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  height: 100vh;

  overflow-x: hidden;
}

#title{
    color:#AD1457;
}

.card-primary{
    border-color: #c0bbb7 !important;
}

#btn_crear{
  color:#ffffff;
  
  background-color: #AD1457;
  float: right;
 
}
#btn_crear:hover{  
   
    background-color: #7e0a3c;    
   
  }

.modal-footer a:hover{    
    background-color:#0275d8;  
    color:#ffffff;  
   
  }

#title{
    color:#AD1457;   
}

#modal-respuesta h6{
  color:#f03f3f;
}

#modal-respuesta p {
  font-size: small;
  color:#f03f3f;
}

#tabla1,#tabla2,#tabla3,#tabla4 {
  overflow:scroll;
  height:100%;  
 
}


#tabla table {
 width:380px;
 background-color:lightgray;
}


#spamloader {
  font-size: x-large;
  color:#fafafa
}

#eps_pcte,
#regimen_pcte{
  font-size: 14px;

}

#link_pqrs{
  font-size: 13px; 
  font-style: unset;
}


/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.card::-webkit-scrollbar {
  -webkit-appearance: none;
}

.card::-webkit-scrollbar:vertical {
  width:10px;
}

.card::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
  display: none;
} 

.card::-webkit-scrollbar:horizontal {
  height: 10px;
  display: none;
}

.card::-webkit-scrollbar-thumb {
  background-color: #AD1457;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.card::-webkit-scrollbar-track {
  border-radius: 10px;  
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a{
    background-color: #AD1457 ;
    border-color:  #AD1457 ;
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid #AD1457 ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color:  #AD1457 ;
    border-color: #AD1457;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #AD1457
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }
.btn-edit,
.btn-edit:defined,
.btn-edit:active {
  color:#AD1457;
  text-shadow: 0 -px 0 #ffffffe8; 
     
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;        

 
}


.btn-edit:hover
{

  background-color: #ebeaea;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;        

 
}


img {
    float: left;
    margin-left: 15px;     
}

#titleNoty {
    font-size: medium;
    font-weight: bold;
    color:#1c1b1b;
  }

#messageNoty {
    font-size: small;
    color:#646263;
  }

  .content-wrapper{
    font-size: 85%;
  }

  #tabla_noti{
  overflow:scroll;
  height:380px;  
 
}

  
.sidebar{
    background-color:#AD1457;
    height: 100%;
    
}

.sidebar li i,
.sidebar li p{
    color: #f5f5f5;
   
}

.sidebar li {
    transition: 0.7s;
    transition-property:background-color ;    
  }

.sidebar li:hover {
    background-color: #7e0a3c;
    text-decoration: none;
    color:#fff;    
    border-radius: 5px;
}


.brand-link{
    background-color:#AD1457;   
}

#item_ips span {
    font-size: small;
    color:#fff;
  }

.activo{
    /* font-weight: bold; */
    background-color:#7e0a3c ;
}

:root {
    --color-green: #00a878;
    --color-red: #fe5e41;
    --color-button: #fdffff;
    --color-black: #000;
}
.switch-button {
    display: inline-block;
}
.switch-button .switch-button__checkbox {
    display: none;
}
.switch-button .switch-button__label {
    background-color: #fe5e41;
    background-color: var(--color-red);
    width: 4rem;
    height: 2rem;
    border-radius: 3rem;
    display: inline-block;
    position: relative;
}
.switch-button .switch-button__label:before {
    transition: .2s;
    display: block;
    position: absolute;
    width: 2rem;
    height: 2rem;
    background-color: #fdffff;
    background-color: var(--color-button);
    content: '';
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 1px #000;
    box-shadow: inset 0px 0px 0px 1px var(--color-black);
}
.switch-button .switch-button__checkbox:checked + .switch-button__label {
    background-color: #00a878;
    background-color: var(--color-green);
}
.switch-button .switch-button__checkbox:checked + .switch-button__label:before {
    -webkit-transform: translateX(2rem);
            transform: translateX(2rem);
}
#cont{
    max-width: 900px;
    height: 100hv;
}

#cont #title{
    color:#AD1457;
    text-align: center;
}

/* Inactive tab */
#cont .myClass {
    background-color: white;
    color: red;
}
.myClass ul > li > a:hover {
    background-color: pink;
}

/* Active tab */
.myClass ul > li.active > a,
.myClass ul > li.active > a:hover,
.myClass ul > li.active > a:focus {
    background-color: red;
    color: white;
}

/* Disabled tab */
.myClass ul > li.disabled > a {
    background-color: lightgray;
    color: darkgray;
}

#modal-editconvenio hr{
    border-color:#AD1457;
}

/* style 7 */

.inputfile-7 + label {
    color: #a5a5a5;
  }
  
  .inputfile-7:focus + label,
  .inputfile-7.has-focus + label,
  .inputfile-7 + label:hover {
    color: #575655;
  }
  
  .inputfile-7 + label figure {
    width: 30px;
 
  }
  
  .inputfile-7:focus + label figure,
  .inputfile-7.has-focus + label figure,
  .inputfile-7 + label:hover figure {
    background-color: #bfbfbf;
  }
  
  .inputfile-7 + label svg {
    width: 100%;
    height: 100%;
    fill: #fff;
  }

 
.checkbox {
	-webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    -ms-appearance: checkbox;
    -o-appearance: checkbox;
	}
#btn_load {
    color:#ffffff;
    background-color: #AD1457;
    float: center;
   
  }
#btn_load:hover{  
     
      background-color: #7e0a3c;    
     
    }


    input[type="file"] {
        display: none;
    }
    .custom-file-upload {
        color:#ffffff;
        background-color: #AD1457;
        
        
        padding: 6px 12px;
        cursor: pointer;
    }


  .card hr{
      border-color:#AD1457;
  }

  .card p {
    font-size: medium;
    color:#646263;
  }

#btn_save{
  color:#ffffff;  
  background-color: #AD1457;
  
 
}
#btn_save:hover{  
   
    background-color: #7e0a3c;    
   
  }


#tabla_intervalos {
  overflow:scroll;
  height:50px;
 
}
#tabla_intervalos table {
 width:100px;
 background-color:lightgray;
}

.btn-see,
.btn-see:defined,
.btn-see:active {
  color:#AD1457;
  text-shadow: 0 -px 0 #ffffffe8; 
     
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;        

 
}

#li_derecha{
    
  margin-left:64%;
  
}

/* style 5 */

.inputfile-5 + label {
  color: #666664;
}

.inputfile-5:focus + label,
.inputfile-5.has-focus + label,
.inputfile-5 + label:hover {
  color: #100a04;
}

.inputfile-5 + label figure {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #d4dcd9;
  display: block;
  padding: 20px;
  margin: 0 auto 10px;
}

.inputfile-5:focus + label figure,
.inputfile-5.has-focus + label figure,
.inputfile-5 + label:hover figure {
  background-color: #bfbfbf;
}

.inputfile-5 + label svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}

#dropdown_options{  
  color:#AD1457;  
  background-color: #ffffff;    
 
}

#dropdown_options:hover{  
  color:#ffffff;  
  background-color: #c03232;    
 
}

#btn_noreaccion{  
  color:#615e5e;
  background: #ffffff;
  border-radius: 10px;
  border: none;
 
}



#btn_reaccion{  
  color:#AD1457;
  border: none;
  background: #ffffff;
  border-radius: 10px;
 
}

#btn_reaccion:hover{  
  color:#AD1457;
  border: none;
  background: #e1e1e1;
  border-radius: 10px;
 
}


#btn_noreaccion_interesa{  
  color:#615e5e;
  border: 1px solid #615e5e;
  background: #ffffff;
  border-radius: 10px;
 
}

#btn_noreaccion_interesa:hover{  
  color:#615e5e;
  border: 1px solid #615e5e;
  background: #ffffff;
  border-radius: 10px;
 
}

#btn_reaccion_interesa{  
  color:#AD1457;
  border: 1px solid #AD1457;
  background: #ffffff;
  border-radius: 10px;
 
}

#btn_reaccion_interesa:hover{  
  color:#AD1457;
  border: 1px solid #AD1457;
  background: #e1e1e1;
  border-radius: 10px;
 
}

#tabla_imgs {
  overflow-y:scroll;
  height:290px;  
 
}

#tabla_reacciones {
  overflow-y:scroll;
  height:400px;  
 
}





#vdsdetalle{
  width:100%;
  max-width:765px;
}


#imgs,
#vdos{
  border:1px solid #AD1457;
}



@media only screen and (max-width: 1920px) {
  
  #modal-detallepubli{  
    left: 350px;  
    margin-left: -225px;
  }

  #imgsdetalle{
    width:100%;
    max-width:735px;
  }
  
}

@media only screen and (max-width: 767px) {

  #modal-detallepubli{ 
    left:0px; 
    margin-left: 0;
  }

  #imgsdetalle{
    width:100%;
    max-width:535px;
    margin-left: auto;
  }

  

}

#info_reaccion{
  color: #100a04;
}
 







#publicaciones{
        margin: 0 auto; /* Added */
        float: none; /* Added */
        margin-bottom: 10px; /* Added */
        box-shadow: 2px 2px 5px #999;
        
        border-top: 1px solid #d3d3d3 !important;
        border-bottom: 1px solid #d3d3d3 !important;
        border-left: 1px solid #d3d3d3 !important;
        border-right: 1px solid #d3d3d3 !important;
        border-radius: 10px;
        
}

#image_publi {
  display: block;
  float:left;
}

#textarea_descripcion{
  white-space: pre-line;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
}


#textarea_descripcion:hover{
  white-space: pre-line;
  display: block;
  
}


#textarea_descripcion_vermas{
  white-space: pre-line;
}


#btn_vermas,
#btn_vermas:defined {
  color:#AD1457; 
  background-color:  #ffffff;
  border: none;
  font-size: small;
}

#btn_vermas:hover{  
  color:#7e0a3c;  
  background-color:#ffffff;    
 
  border: none;
  border-color: #ffffff;
 
}

#btn_vermenos,
#btn_vermenos:defined {
  color:#AD1457; 
  background-color:  #ffffff;
  border: none;
  font-size: small;
}

#btn_vermenos:hover{  
  color:#7e0a3c;  
  background-color:#ffffff;    
 
  border: none;
  border-color: #ffffff;
 
}


#image_publi {

  display: block;
  margin-left: auto;
  margin-right: auto;
}


.aws-btn {

--slider-height-percentage: 60%;
--slider-transition-duration: 700ms;
--organic-arrow-thickness: 4px;
--organic-arrow-border-radius: 0px;
--organic-arrow-height: 40px;
--organic-arrow-color: #538bd5;
--control-button-width: 10%;
--control-button-height: 25%;
--control-button-background: transparent;
--control-bullet-color: #62a4fa;
--control-bullet-active-color: #538bd5;
--loader-bar-color: #851515;
--loader-bar-height: 6px;
}



@media only screen and (max-width: 1920px) {
  #portada{   
      height:400px;  
      margin-left: auto;
    }

  #avatar{   
      height:180px;  
      margin-left: auto;
    }

    #perfil_avatar{
      position: absolute; right: 50px; bottom: 375px;
      border-radius: 150px;
      /*border: 5px solid #ffffff !important;*/
     
    }
  
  }

  @media only screen and (max-width: 767px) {
    #portada{   
        height:auto;
        margin-left: auto;
      }

      #avatar{   
        height:70px;  
        margin-left: auto;
      }

      #perfil_avatar{
        position: absolute; right: 30px; bottom: 480px;
        border-radius: 150px;
        /*border: 5px solid #ffffff !important;*/
       
      }      
    
    }

/*#avatar
  {
    position:absolute;
    height:300px;
    width:300px;
    z-index: -1;
  } */

#nit_cod{
    color:#838181; 
    font-size: medium;
 } 

#linea{
    border-color:#afafaf !important;
    border: 1px solid;

 }

#hr_vertical{

    border-left: 2px solid #afafaf;
      
}

#web{  
  color:#666565; 
  background-color:  #ffffff;
  border-radius: 15px;
  border: 0px;
      
  }

#web:hover{  
  color:#AD1457;  
  background-color:#ffffff;    
  border-radius: 15px;
  border: 0px;
   
   
  }

#info{
    color:#AD1457;
    font-size: 18px;
  }

#act_plan{
    color:#6b6b6b; 
   
}

#cod_membresia{
  width: 80px;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 14px;
}

#modal-activamembresia{  
  top: 100px;  
  
}


#carouselExampleControls{
    background-color: #e4e4e4;
}

.carousel-control-prev-icon{ 

    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%767676' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");

}

.carousel-control-next-icon{ 
     
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%767676' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}


#btn-comprar {
  color:#ffffff; 
  background-color: #AD1457;
  border-radius: 15px; 
  display: block;
  margin-left: 1em;
}

#btn-comprar:hover{  
  color:#ffffff;  
  background-color: #7e0a3c;    
 
}

#espacios{
    margin-top: 0;
    margin-bottom: 0;
    color: #3d3d3d;
    font-weight: bold;
}

#info_institucion{
    margin-top: 0;
    margin-bottom: 0;  
}

#texto{
    
    color: #3d3d3d;
    font-weight: bold;
    font-size: large;
}

#titulo_plan{
    color:#AD1457;
  
}

#custom-tabs-three-home{
  position: relative;
}

#perfil_cover{
  position: relative;
}



#act_avatar{

  border: 5px solid #dfdfdf !important;
 
}

#file_portada{
  position: absolute; bottom: 20px; 
}

#file_avatar{
  position: absolute; bottom: -10px; 
}

#fondo_modal_compra{
  background-color: #FBF4F7 ;
  
}

#btn_compra_paso1{
  background-color: #FBF4F7 ;
}

#btn_compra{
  background-color: #AD1457 ;
  border-color: #AD1457 ;
}

#btn_compra:hover{
  background-color: #7e0a3c ;
  border-color: #7e0a3c ;
}

#btn-llaveswompi,
#btn-llaveswompi:defined {
  color:#3d3d3d; 
  background-color:  #ffffff;
  border-color: #dfdfdf;
  border-radius: none;
  border: none;
  font-size: medium;
}

#btn-llaveswompi:hover{  
  color:#AD1457;  
  background-color:#ffffff; 
  border-color: #dfdfdf;   
  border-radius:none;
  border:none;
 
}

#btn-verdetalle,
#btn-verdetalle:defined {
  color: #3d3d3d; 
  border-radius: none;
  border: none;
  font-size: medium;
  text-decoration-line: underline;
}

#btn-verdetalle:hover{  
  color: #7e0a3c;
  border-radius: none;
  border: none;
  text-decoration-line: underline; 
}


#img_pasoss{

  display: block;
  margin: auto;

}

.tab-contente {
  height:auto;
}

/* style 6 */

.inputfile-6 + label {
  color: #666664;
}

.inputfile-6:focus + label,
.inputfile-6.has-focus + label,
.inputfile-6 + label:hover {
  color: #100a04;
}



.inputfile-6:focus + label figure,
.inputfile-6.has-focus + label figure,
.inputfile-6 + label:hover figure {
  background-color: #bfbfbf;
}

.inputfile-6 + label svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}

#tabla_publicaciones {
  overflow:scroll;
  height:500px;
}

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.card-body::-webkit-scrollbar {
  -webkit-appearance: none;
}

.card-body::-webkit-scrollbar:vertical {
  width:10px;
}

.card-body::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
  display: none;
} 

.card-body::-webkit-scrollbar:horizontal {
  height: 10px;
  display: none;
}

.card-body::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.card-body::-webkit-scrollbar-track {
  border-radius: 10px;  
}






  

#btn-options {
    color:#ffffff;
    background-color: #AD1457;
    float: center;
    width: 100%;
   
  }

#btn-options:hover{  
     
      background-color: #7e0a3c;    
     
  }

#btn-platinium {
      color:#ffffff;
      background-color: #3c474f;
      float: center;
      width: 100%;
     
  }

#btn-golden {
    color:#ffffff;
    background-color: #3f2311;
    float: center;
    width: 100%;
   
}

#btn-diamond {
  color:#ffffff;
  background-color: #004740;
  float: center;
  width: 100%;
 
}

#btn-platinium:hover{  
       
        background-color: #2b3338;    
       
  }

#btn-golden:hover{  
       
    background-color: #311b0d;    
   
}

#btn-diamond:hover{  
       
  background-color: #00312d;    
 
}



#nav2{
    background-color: #AD1457;
}


#icono_crea{
    color:#c4c4c4;  
    background-color: #ffffff;
    border:solid 1px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
}

#btn_sigte{
    color:#ffffff;
    
    background-color: #AD1457;
    float: right;
   
}

  #btn_anterior{
    color:#ffffff;
    
    background-color: #AD1457;
    float: left;
   
}

#btn-registrar,
#btn-registrar:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 50%;
  border-radius: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}



#btn-cargar,
#btn-cargar:defined {
  color:#ffffff; 
  background-color: #AD1457;
  
  border-radius: 5px;

  font-size: 10px;
}

#btn_recupera{

  color:#ffffff; 
  background-color: #AD1457;
  border-color: #AD1457;

}

#btn_recupera:hover{

  color:#ffffff; 
  background-color: #7e0a3c;

}

#hr_vertical1{
    border-left: 1px solid #afafaf;      
}

#hr_horizontal1{
    border-top: 1px solid #afafaf;      
}

#medida{
  font-size: 12px;
  color: #666664;
}

#portada_blanco{   
    display: block;
    margin: auto;
    border-radius: 100em;
 }

 #avatar_blanco{   
  height:auto;
  margin-left: auto;
 
}

 #foto_portada {
  position: absolute; right: -300px; bottom: 0px;
  }


#card_body{
  border-radius: 15px;
}

#correo_activacion{
  color: #AD1457;
}

#cod_activa{
  width: 50px;
  margin-left: 4px;
  margin-right: 4px;
}

#exampleCheck1{
  width:30px;
  height:30px;  
  
}

#check_terminos{
  margin-top: 8px;
  margin-left: 20px;
  text-decoration-line: underline;

}

#check_terminos:hover{
  margin-top: 8px;
  margin-left: 20px;
  text-decoration-line: underline;
  color:#AD1457;

}

#asterisco {
 
  color:#f03f3f;
  border-top: 0px;
}

#campos_obligatorios {
  font-size: small;
  border-top: 0px;
  background-color:  #ffffff;
  border: 0px;
}

#campos_obligatorios:hover {
  font-size: small;
  border-top: 0px;
  color: #666664;
  background-color:  #ffffff;
  border: 0px;
}

#p_comprar{
  margin-top: 0;
  margin-bottom: 0;
  color: #3d3d3d;
  font-weight: bold;
  font-size: 16px;
}

#info_compra{
  margin-top: 0;
  margin-bottom: 0;
  color:#9e9e9e;
  font-size: 15px;
}

#h4_compra{
  color: #3d3d3d;
  font-weight: bold;
}

#titulo_terminos{
  text-align: justify;
  text-align: center;
}

#texto_terminos{
  text-align: justify;
  
}




@media only screen and (max-width: 1920px) {

  #img_center {
   
    display: block;
    margin: auto;
    margin-right: 370px;
 
}

  
  #btn-tengocuenta,
  #btn-tengocuenta:defined {
    color:#ffffff; 
    background-color:  #AD1457;
    border-radius: 15px;
    border: 0px;
    margin-right: 120px;
  }
  
  #btn-tengocuenta:hover{  
    color:#d5d5d5;  
    background-color:#AD1457;    
    border-radius: 15px;
    border-color: 1px;  
   
  }
  
}

@media only screen and (max-width: 767px) {

  #img_center {
   
    display: block;
    margin: auto;
    margin-right: 20px;
 
}

  
  #btn-tengocuenta,
  #btn-tengocuenta:defined {
    color:#ffffff; 
    background-color:  #AD1457;
    border-radius: 15px;
    border: 0px;
    margin-right: 20px;
  }
  
  #btn-tengocuenta:hover{  
    color:#d5d5d5;  
    background-color:#AD1457;    
    border-radius: 15px;
    border-color: 1px;  
   
  }

  

}




/* style 6 */

.inputfile-6 + label {
    color: #666664;
  }
  
  .inputfile-6:focus + label,
  .inputfile-6.has-focus + label,
  .inputfile-6 + label:hover {
    color: #100a04;
  }
  
  
  
  .inputfile-6:focus + label figure,
  .inputfile-6.has-focus + label figure,
  .inputfile-6 + label:hover figure {
    background-color: #bfbfbf;
  }
  
  .inputfile-6 + label svg {
    width: 100%;
    height: 100%;
    fill: #fff;
  }

  input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

  
:root {
    --color-green: #00a878;
    --color-red: #fe5e41;
    --color-button: #fdffff;
    --color-black: #000;
}
.switch-button {
    display: inline-block;
}
.switch-button .switch-button__checkbox {
    display: none;
}
.switch-button .switch-button__label {
    background-color: #fe5e41;
    background-color: var(--color-red);
    width: 4rem;
    height: 2rem;
    border-radius: 3rem;
    display: inline-block;
    position: relative;
}
.switch-button .switch-button__label:before {
    transition: .2s;
    display: block;
    position: absolute;
    width: 2rem;
    height: 2rem;
    background-color: #fdffff;
    background-color: var(--color-button);
    content: '';
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 1px #000;
    box-shadow: inset 0px 0px 0px 1px var(--color-black);
}
.switch-button .switch-button__checkbox:checked + .switch-button__label {
    background-color: #00a878;
    background-color: var(--color-green);
}
.switch-button .switch-button__checkbox:checked + .switch-button__label:before {
    -webkit-transform: translateX(2rem);
            transform: translateX(2rem);
}
.firmacanvas{
    border: 1px solid black;
    width: 100%;
    min-height: 400px;
}
img {
    float: left;
    margin-left: 15px;     
}

#nav{
  background-color: #c9c9c9;
}

#footer{
  align-content:center;
}



  

 .div_containe{
    min-height: 100vh;
    background-image: url(../../static/media/fondorecover.2e70f448.jpg);
    background-repeat: no-repeat;
    background-position: 0px 58px center center;
    background-attachment: fixed;
    background-size: cover;
  
  }
  
#estado{
    font-size: 35px;
}

#lavel{
    color: #6f6f6f
}

#btn{

    background-color: #AD1457;
    border-color: #AD1457;
}

#btn_finalizarcompra{

    background-color: #AD1457;
    border-color: #AD1457;
}

#btn_finalizarcompra_false{

    background-color: #AD1457;
    border-color: #AD1457;
}
#btn-paginaprincipal,
#btn-paginaprincipal:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 50%;
  border-radius: 15px;
}

  #btn-paginaprincipal:hover{  
    color:#ffffff;  
    background-color: #7e0a3c;    
  
  }

#btn-volverinicio{  
  color:#AD1457;
  text-decoration-line: underline;
}

#btn-volverinicio:hover{  
  color:#7e0a3c;
  text-decoration-line: underline;
}
#myIframe{
    width: 100%;
    height: 100vh;
    }
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}


.div_container{
  max-width: 450px !important;
  margin-top: 0px;
  border-color:#AD1457 ;   
  height: 200px;
  margin: 0 auto;
  
  
  }

.div_container {
border-color: #AD1457;
}

#titulo_movil{
  color:#6f6f6f;    
}


.card-title {
  color: #AD1457;
}

#image_movil {

  display: block;
  margin-left: auto;
  margin-right: auto;
}


#btn-ttc_movil,
#btn-ttc_movil:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 100%;
  border-radius: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#show_password_movil{
  color:#ffffff;  
  background-color: #AD1457;
}

#btn-ttc:hover,
#show_password_movil:hover{  
  color:#ffffff;  
  background-color: #7e0a3c;    
 
}

#btn-recoveripass,
#btn-recoveripass:defined {
  color:#9d9999; 
  background-color:  #ffffff;
  
  border-radius: 15px;
  border: 0px;
}

#btn-recoveripass:hover{  
  color:#020202;  
  background-color:#ffffff;    
  border-radius: 15px;
  border: 0px;
 
}

#btn-cambiocontra,
#btn-cambiocontra:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 50%;
  border-radius: 15px;
}

#btn-cambiocontra:hover{  
  color:#ffffff;  
  background-color: #7e0a3c;    
 
}


header a {
  color:#646263;
}

.card  a {
  color:#AD1457;
}

.card  a:hover {
  color:#000;
}

header p {
  font-size: small;
  color:#646263;
}

.form-control  {  
  /* border-color:#888484; */
  border-radius: 5px;
  /*background-color: #eee; */
  /*border: 0; */
  outline-color:#8fbff5;
  /*outline:none; */
  border: 1px solid rgb(190, 190, 190);
  
}

.card input{
  font-family: Arial,Helvetica,sans-serif;
       font-size: 18px;
}

#txtPassword,
#input{
  font-size: 15px;
  background-color:transparent;
}


  
