#btn-paginaprincipal,
#btn-paginaprincipal:defined {
  color:#ffffff; 
  background-color: #AD1457;
  width: 50%;
  border-radius: 15px;
}

  #btn-paginaprincipal:hover{  
    color:#ffffff;  
    background-color: #7e0a3c;    
  
  }

#btn-volverinicio{  
  color:#AD1457;
  text-decoration-line: underline;
}

#btn-volverinicio:hover{  
  color:#7e0a3c;
  text-decoration-line: underline;
}